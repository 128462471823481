
.sideViewPane{
    height: 100%;
    width: 13rem;
    top:0;
    left:0;
    z-index: 1;
    position: fixed;
    background-color: rgb(4, 54, 54);
    overflow-y: scroll;
    .sideViewPaneHeader{
        height: 2rem;
        width: calc(100%-5px);
        padding-top: 0.5rem;
        padding-left: 5px;
        .sideViewPaneHeaderText{
            font-family: 'Courier New', Courier, monospace;
            font-weight: 700;
            font-size: 2em;
            color: white;
        }
        :hover{
            cursor: pointer;
        }
    }
    
    .sideViewPaneSubHeader{
        height: 2rem;
        width: calc(100%-5px);
        padding-left: 5px;
        padding-top: 1rem;
        .sideViewPaneSubHeaderText{
            font-family: 'Courier New', Courier, monospace;
            font-weight: 100;
            font-size: 1em;
            color: white;
        }
    }
    .sideViewContentArea{
        margin-top: 2rem;
        bottom: 0;
        height: calc(100%-4rem);
        width: calc(100%-5px);
        padding-left: 5px;
        .sideViewContentItems{
            height: 2rem;
            width: 100%;
            padding-left: 1rem;
            .sideViewContentItemsText{
                font-family: 'Courier New', Courier, monospace;
                font-weight: 400;
                font-size: 1em;
                color: white;
            }
        }
        :hover{
            background-color: rgb(4, 94, 94);
            cursor: pointer;
        }
        :active{
            background-color: rgb(5, 148, 148);
        }
    }
}