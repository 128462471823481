.error-page{
    height: 100%;
    width: 100%;
    .error-page-body{
        height: calc(100%-3rem);
        width: 100%;
        background-color: rgb(230, 255, 255);
    }
    .error-page-footer{
        height: 3rem;
        width: 100%;
        background-color: rgb(230, 255, 255);
    }
}