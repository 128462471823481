.footerArea{
    height: 3rem;
    width: calc(100%-13rem);
    position: fixed;
    top: calc(100%-3rem);
    bottom:0;
    right: 0;
    left: 13rem;
    background-color: rgb(204, 252, 252);
    border-top: 0.5px solid rgb(140, 255, 255);
    // padding-top: 10px;
    .footerTextArea{
        margin-top: 10px;
        .footerTextAreaText{
            font-family: 'Courier New', Courier, monospace;
            font-weight: 100;
            font-size: 1em;
            color: grey;
        }
    }
}