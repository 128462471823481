
.mainViewArea{
    height: calc(100%-13rem);
    width: calc(100%-13rem);
    position: fixed;
    top:10rem;
    right: 0;
    left: 13rem;
    bottom: 3rem;
    background-color: rgb(230, 255, 255);
    overflow: scroll;
    .HighLevelStatsArea{
        height: 7rem;
        width: 100%;
        // background-color: rgb(3, 143, 143);
        padding-left: 2rem;
        .HighLevelStatsAreaPanel{
            height: 7rem;
            width: 80%;
            // background-color: rgb(3, 59, 59);
            .HighLevelStatsBox{
                height: 7rem;
                // width: 10rem;
                // background-color: rgb(205, 211, 211);
                // margin-left: 10px;
                // border: 1px solid black !important;
                border-bottom: 1px solid black !important;
                // border-radius: 10px 10px 10px 10px;
                // padding-top: 1rem;
                .HighLevelStatsBoxContent{
                    height: 5rem;
                    width: 100%;
                    // background-color: rgb(4, 54, 54);
                    border-radius: 0px 0px 10px 10px;
                    // padding-top: 2rem;
                    .HighLevelStatsBoxContentText{
                        font-family: 'Courier New', Courier, monospace;
                        font-weight: 700;
                        font-size: 2vw; //vw - ViewportWidth for responsive content
                    }
                    :hover{
                        opacity: 0.3;
                    }
                }
                .HighLevelStatsBoxHeader{
                    margin-top:1rem;
                    height: 2rem;
                    width: 100%;
                    // background-color: rgb(80, 255, 255);
                    border-radius: 10px 10px 0px 0px;
                }
            }
            // :hover{
            //     background-color: rgb(228, 228, 228);
            // }
        }
    }
    .mainViewAreaHeading{
        height: 2rem;
        width: 100%;
        padding-left: 2rem;
        padding-top: 5px;
        // background-color: rgb(7, 95, 95);
        .mainViewAreaHeadingText{
            font-family: 'Courier New', Courier, monospace;
            font-weight: 700;
            font-size: 1em;
            // color: cyan;
        }
    }
    .countryComparisionHeader{
        width: 90%;
        height: 7rem;
        .countryComparisionPanel{
            height: 100%;
            margin-left: 2rem;
            // background-color: pink;
            .countryComparisionButton{
                height: 30%;
                width: 22rem;
                padding-top: 10px;
                background-color: rgb(201, 201, 201);
                border: 1px solid rgb(0, 0, 0);
                border-radius: 10px 10px 10px 10px;
                .countryComparisionText{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 700;
                    font-size: 1em;
                }
            }
            :hover{
                background-color: rgb(218, 218, 218);
            }
            :active{
                background-color: rgb(190, 190, 190);
            }

            .countryComparisionList{
                margin-top: 5px;
                height: 50%;
                width: 100%;
                // background-color: rgb(201, 201, 201);
                .countryComparisionListText{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 700;
                    font-size: 1em;
                }
            }
        }
    }
    .allCountryTableArea{
        height: calc(100%-10rem);
        width: 100%;
        margin-bottom: 2rem;
        padding-left: 2rem;
        // background-color: rgb(80, 255, 255);
        .allCountryTable{
            // border: 1px solid rgb(0, 0, 0);
            width: 90%;
            .allCountryTableRow{
                border-bottom: 1px solid black;
                .allCountryTableCol{
                    // border: 1px solid black;
                    border-bottom: 1px solid black;
                    height: 4rem;
                    padding-left: 5px;
                    padding-right: 5px;
                    .allCountryTableText{
                        font-family: 'Courier New', Courier, monospace;
                        font-weight: 700;
                        font-size: 1.5vw;
                    }
                }
            }
            .allCountryTableRowHeader{
                .allCountryTableColHeader{
                    height: 3rem;
                    border-radius: 5px 5px 5px 5px;
                    background-color: rgb(204, 252, 252);
                    padding-left: 5px;
                    padding-right: 5px;
                    .allCountryTableTextHeader{
                        font-family: 'Courier New', Courier, monospace;
                        font-weight: 700;
                        font-size: 1rem;
                    }
                }
                :hover{
                    background-color: rgb(155, 190, 190);
                }
                :active{
                    background-color: rgb(126, 160, 160);
                }
            }
        }
    }
    .chartContainerArea{
        width: 80%;
        height: 30rem;
        margin-top: 2rem;
        // background-color: rgb(213, 255, 255);
        margin-bottom: 2rem;
        margin-left: 2rem;
        .chartButtonPanel{
            width: 100%;
            height: 3rem;
            // background-color: rgb(131, 131, 131);
            .chartButton{
                float: left;
                width: 15%;
                height: 2rem;
                // background-color: rgb(202, 202, 202);
                border: 1px solid rgb(141, 141, 141);
                border-radius: 5px 5px 5px 5px;
                margin-right: 2rem;
                padding-top: 5px;
                .chartButton-Text{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 700;
                    font-size: 1em;
                    color: grey;
                }
            }
            :hover{
                background-color: rgb(211, 211, 211);
                cursor: pointer;
            }
            :active{
                background-color: rgb(182, 182, 182);
                cursor: pointer;
            }

            .chartButton-enabled{
                float: left;
                width: 15%;
                height: 2rem;
                // background-color: rgb(182, 182, 182);
                border: 1px solid rgb(73, 73, 73);
                border-radius: 5px 5px 5px 5px;
                margin-right: 2rem;
                padding-top: 5px;
                .chartButton-Text{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 700;
                    font-size: 1em;
                    color: white;
                }
            }
            :hover{
                background-color: rgb(211, 211, 211);
                cursor: pointer;
            }
            :active{
                background-color: rgb(182, 182, 182);
                cursor: pointer;
            }
        }
        .chartResponsiveContainer{
            // background-color: khaki;
            margin-top: 1rem;
            width: 100%;
            height: calc(100%-3rem);
        }
        .tooltip-card{
            height: auto;
            width: auto;
            .tooltip-card-header{
                padding-right: 1rem;
                padding-left: 1rem;
                padding-top: 0.5rem;
                height: 3rem;
                width: 100%;
                border-radius: 5px 5px 0px 0px;
                background-color: rgba(221, 221, 221, 0.733);
                border: 1px solid rgb(173, 173, 173);
                border-bottom: 0 !important;
                .tooltip-card-header-text{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 700;
                    font-size: 1em;
                    color: black;
                }
                .tooltip-card-header-subtitle{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 700;
                    font-size: 0.5em;
                    color: rgb(235, 13, 13);
                    font-style: oblique;
                }
            }
            .tooltip-card-body{
                padding-right: 1rem;
                padding-left: 1rem;
                padding-top: 0.5rem;
                padding-bottom: 1rem;
                height: calc(100%-3rem);
                width: 100%;
                border-radius: 0px 0px 5px 5px;
                background-color: rgba(221, 221, 221, 0.733);
                border: 1px solid rgb(173, 173, 173);
                border-top: 0 !important;
                .tooltip-card-body-text{
                    font-family: 'Courier New', Courier, monospace;
                    font-weight: 400;
                    font-size: 1em;
                }
            }
        }
    }
    .ack-Container{
        height: 100%;
        width: 100%;
        // background-color: rgb(182, 182, 182);
        .ack-Container-text-area{
            height: 90%;
            width: 60%;
            padding-top:3rem;
            // background-color: rgb(243, 179, 179);
            .ack-Container-text{
                font-family: 'Courier New', Courier, monospace;
                font-weight: 400;
                font-size: 1em;
            }
        }
    }
}

.portal-text{
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    font-size: 1em;
}